<template>
  <div>
    <div>
      <!-- Summary Card -->
      <b-form-group>
        <b-form-radio-group
          v-model="selectedMiniGame"
          v-ripple.400="'rgba(200, 191, 199, 0.15)'"
          :options="filteredMiniGame"
          buttons
          button-variant="primary"
          class="full-width"
          @change="handleRadioMiniGame($event)"
        />
      </b-form-group>

      <!-- Main Content -->
      <div class="d-flex main-content">
        <!-- iframe Section -->
        <b-card class="iframe-card" style="flex: 1; margin-right: 15px;">
          <template
          >
            <iframe
              ref="myIframe"
              :src="getIframeSrc"
              style="width: 1100px; height: 660px; border-width: 0.1px; border-color: transparent;"
              @load="onIframeLoad"
            >
            </iframe>
          </template>
        </b-card>

        <!-- Cart Section -->
        <template
          v-if="windowWidth >= 1490"
        >
          <div class="cart-aside-container">
            <div class="cart-aside">
              <section>
                <mini-cart
                  :disabled="isCartDisabled"
                  :cart-items.sync="cartItems"
                  :filtered-set-menu="filteredSetMenu"
                  :filtered-odds-for-buttons="filteredOddsForButtons"
                  :filtered-set-basic="filteredSetBasic"
                  :is-loading="isLoading"
                  :window-width="windowWidth"
                  :remaining-times="remainingTimes"
                  :selected-mini-game="selectedMiniGame"
                  @remove-cart="removeCart"
                  @reset-cart="resetCart"
                  @create-bet="createBet"
                />
              </section>
            </div>
            <div v-if="isCartDisabled" class="lock-overlay">
              <span>🔒</span>
            </div>
          </div>
        </template>
        <template v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-right
            variant="primary"
            class="cart-btn btn-icon position-fixed"
            style="bottom: 5%; right: 75px; padding: 10px; overflow:visible;"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
            />
            <b-badge
              class="position-absolute text-bold badge-glow m-0"
              style="background-color: #0ba81c; right: -7px; top: -10px;"
              pill
            >
              {{ cartItems.length }}
            </b-badge>
            <span v-if="windowWidth > 600"> MINI CART </span>
          </b-button>
          <b-sidebar
            id="sidebar-right"
            bg-variant="white"
            right
            backdrop
            shadow
          >
            <mini-cart
              :disabled="isCartDisabled"
              :cart-items.sync="cartItems"
              :filtered-set-menu="filteredSetMenu"
              :filtered-odds-for-buttons="filteredOddsForButtons"
              :is-loading="isLoading"
              :window-width="windowWidth"
              :remaining-times="remainingTimes"
              :selected-mini-game="selectedMiniGame"
              @remove-cart="removeCart"
              @reset-cart="resetCart"
              @create-bet="createBet"
            />
            <div v-if="isCartDisabled" class="lock-overlay">
              <span>🔒</span>
            </div>
          </b-sidebar>
        </template>
      </div>

      <!-- Game Result -->
      <div class="" style="width: 1140px">
        <b-card>
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchMiniGameResults"
            :fields="fields1"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            style="text-align: center; font-size: small"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ totalRows - (perPage * (currentPage - 1)) - data.index }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {BBadge, BButton, BCard, BFormGroup, BFormRadio, BFormRadioGroup, BRow, BSidebar, VBToggle, BTable} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {createNamespacedHelpers} from 'vuex'

import {
  FETCH_GAME_SET_COMBINATION,
  FETCH_GAME_SET_ITEM,
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ODDS_CUSTOM,
  FETCH_SET_EVENT,
} from '@/store/settings/action'
import {CLEAR_CART_ITEM, REMOVE_CART_ITEM} from '@/store/cart/mutation'
import {ADD_CART, UPDATE_CART_ITEM} from '@/store/cart/action'
import {BET} from '@/store/bet/action'
import { FETCH_GAME_SET_BASIC } from '@/store/result/action'

import moment from 'moment-timezone'
import MiniCart from './Mini-Cart.vue'
import axios from "axios";

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'
const fmt2 = 'YYYYMMDD'

const settingsStore = createNamespacedHelpers('settingsStore')
const cartStore = createNamespacedHelpers('cartStore')
const betStore = createNamespacedHelpers('betStore')
const resultStore = createNamespacedHelpers('resultStore')

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BBadge,
    BFormGroup,
    BSidebar,
    BFormRadioGroup,
    BFormRadio,
    BTable,
    // Child-Components
    MiniCart,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      windowWidth: 0,
      userIp: localStorage.getItem('ip'),
      selectedMiniGame: '',
      isLoading: false,

      // 게임별 카트 비활성화 상태
      isCartDisabled: false,

      // 카운트다운 진행 중인 타이머에 대한 정보를 관리
      remainingTimes: {
        namedPowerball5: null,
        namedPowerball3: null,
        namedLadder5: null,
        namedLadder3: null,
        entryPowerball5: null,
        entryLadder5: null,
        eosPowerball5: null,
      },

      // 각 게임에 대한 타이머 갱신 주기 설정 (초)
      gameResetIntervals: {
        namedPowerball5: 300,
        namedPowerball3: 180,
        namedLadder5: 300,
        namedLadder3: 180,
        entryPowerball5: 300,
        entryLadder5: 300,
        eosPowerball5: 300,
      },

      // 타이머를 추적하는 인터벌 ID
      timers: {
        namedPowerball5: null,
        namedPowerball3: null,
        namedLadder5: null,
        namedLadder3: null,
        entryPowerball5: null,
        entryLadder5: null,
        eosPowerball5: null,
      },

      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 0,
      notReadCnt: 0,
      currentPage: 1,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'idx',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'requestId', sortable: false, label: '배팅회차', thClass: 'col5',
        },
        {
          key: 'betDate', sortable: false, label: '배팅내역', thClass: 'col3',
        },
        {
          key: 'sports', sortable: false, label: '배팅일시', thClass: 'col2',
        },
        {
          key: 'fold', sortable: false, label: '배팅금', thClass: 'col3',
        },
        {
          key: 'totalOdds', sortable: false, label: '총배당', thClass: 'col3',
        },
        {
          key: 'totalHitCash', sortable: false, label: '적중금액', thClass: 'col3',
        },
        {
          key: 'betResult', sortable: false, label: '적중', thClass: 'col3',
        },
        {
          key: 'delete', sortable: false, label: '삭제', thClass: 'col3',
        },
      ],
      fetchMiniGameResults: [
        {},
      ],
    }
  },
  computed: {
    ...settingsStore.mapGetters([
      'fetchSetEvent',
      'fetchGameSetMenu',
      'fetchGameSetItem',
      'fetchGameSetOddsCustom',
      'fetchGameSetCombination',
    ]),
    ...cartStore.mapGetters([
      'cartItems',
    ]),
    ...resultStore.mapGetters([
      'clientFetchGameSetBasic',
    ]),
    formatValue() {
      return value => {
        // console.log(value)
        if (!value) return
        if (this.windowWidth < 400) return `${value.slice(0, 3)}`
        if (this.windowWidth < 500) return `${value.slice(0, 6)}`
        return value
      }
    },
    getIframeSrc() {
      switch (this.selectedMiniGame) {
        case 'namedPowerball5':
          return 'https://bepick.net/live/named_power/scrap'
        case 'namedPowerball3':
          return 'https://bepick.net/live/named_power3/scrap'
        case 'namedLadder5':
          return 'https://bepick.net/live/ntry_pwladder/scrap'
        case 'namedLadder3':
          return 'https://bepick.net/live/named_pwladder3/scrap'
        case 'entryPowerball5':
          return 'https://bepick.net/live/ntry_power/scrap'
        case 'entryLadder5':
          return 'https://bepick.net/live/ntry_pwladder/scrap'
        case 'eosPowerball5':
          return 'https://bepick.net/live/eosball5m/scrap'
        default:
          return ''
      }
    },
    filteredSetMenu() {
      const result = this.fetchGameSetMenu.filter(item => item.useYn === 'Y' && item.name === 'Mini-Game')
      return result.length > 0 ? result : [{}]
    },
    filteredMiniGame() {
      const result = this.fetchGameSetItem
        .filter(item => item.useYn === 'Y' && item.menuName === 'Mini-Game' && item.site === this.$site)
        .map(item => ({ text: item.nameCustom, value: item.name }))
      return result.length > 0 ? result : [{}]
    },
    filteredOddsForButtons() {
      const result = this.fetchGameSetOddsCustom.filter(item => item.type === 'minigame')
      return result
    },
    filteredSetBasic() {
      const result = this.clientFetchGameSetBasic
      return result
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.init()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: this.userData.userid,
      menu: '',
      menuCategory: '',
    })
    this.stopAutoFetch()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetEvent: FETCH_SET_EVENT,
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsCustom: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetCombination: FETCH_GAME_SET_COMBINATION,
    }),
    // Cart
    ...cartStore.mapMutations({
      clearCart: CLEAR_CART_ITEM,
    }),
    ...cartStore.mapActions({
      addToCart: ADD_CART,
      updateCart: UPDATE_CART_ITEM,
      removeCartItem: REMOVE_CART_ITEM,
    }),
    // Bet
    ...betStore.mapActions({
      createBetHistory: BET,
    }),
    ...resultStore.mapActions({
      $clientFetchGameSetBasic: FETCH_GAME_SET_BASIC,
    }),
    // Settings
    onResize() {
      this.windowWidth = window.innerWidth
      this.cartAsideWidth = (document.querySelector('.cart-aside') || { offsetWidth: 0 }).offsetWidth
    },
    init() {
      this.selectedMiniGame = 'namedPowerball5'
      this.isLoading = true
      this.setMenu()
      this.fetchData()
      // this.resetCart()
      // 컴포넌트가 로드될 때 타이머 자동 시작
      this.syncTimer('namedPowerball5')
      this.syncTimer('namedPowerball3')
      this.syncTimer('namedLadder5')
      this.syncTimer('namedLadder3')
      this.syncTimer('entryPowerball5')
      this.syncTimer('entryLadder5')
      this.syncTimer('eosPowerball5')
    },
    async setMenu() {
      await this.$fetchGameSetItem({
        site: this.$site,
      })
      await this.$fetchGameSetOddsCustom({
        site: this.$site,
      })
      await this.$clientFetchGameSetBasic({
        site: this.$site,
      })
    },
    createUid() {
      this.uid = moment(new Date())
    },
    // ##.Deposit init
    async fetchData() {
      this.isLoading = false
      this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: this.userData.userid,
        menu: 'MiniGame',
        menuCategory: this.selectedMiniGame,
      })
    },
    stopAutoFetch() {
      clearInterval(this.intervalId)
    },
    handleRadioMiniGame(value) {
      console.log('miniGame:::', value)
      this.selectedMiniGame = value
      // this.startTimer(this.selectedMiniGame) // 시작할 때 타이머 초기화
      this.fetchData()
    },
    removeCart(item) {
      this.removeCartItem(item)
    },
    resetCart() {
      this.clearCart()
    },
    createBet(item) {
      console.log('createBet', item)
      this.createBetHistory(item)
    },
    onIframeLoad() {
      console.log('Iframe loaded!')
    },
    // 타이머 시작 및 관리
    // 기준 시각과 현재 시각을 동기화하여 타이머 시작
    syncTimer(miniGame) {
      const currentTime = new Date()
      const interval = this.gameResetIntervals[miniGame] // 게임별 리셋 주기 (초)
      const baseOffset = -24 // 기준 시각에 대한 보정치 (초)
      const betTimeClose = this.clientFetchGameSetBasic[0]?.betTimeCloseMini

      // 현재 시간을 기준으로 보정치와 인터벌에 따른 남은 시간 계산
      const elapsedSinceOffset = Math.floor((currentTime.getTime() / 1000) - baseOffset)
      // 남은 시간을 설정
      this.remainingTimes[miniGame] = interval - (elapsedSinceOffset % interval)

      // 타이머를 설정하여 초마다 업데이트
      if (this.timers[miniGame]) clearInterval(this.timers[miniGame]) // 기존 타이머 초기화
      this.timers[miniGame] = setInterval(() => {
        this.remainingTimes[miniGame] -= 1

        // 남은 시간이 0이 되면 interval 값으로 초기화
        if (this.remainingTimes[miniGame] <= 0) {
          this.remainingTimes[miniGame] = interval
        }

        // 업데이트된 남은 시간에 따라 특정 게임의 카트 비활성화 상태 변경
        // 남은 시간에 따라 카트 비활성화 상태 설정
        this.isCartDisabled = this.remainingTimes[this.selectedMiniGame] <= betTimeClose
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
.cart-aside {
  flex: 0 0 285px; /* 고정된 너비 */
  width: 285px;
  max-width: 285px !important;
}
.cart-aside-container {
  position: relative; /* 부모 요소에 상대적 위치 설정 */
}

.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 99%;
  height: 108%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1; /* 오버레이가 카트 영역 위에 표시되도록 */
}

.lock-overlay span {
  font-size: 48px; /* 자물쇠 아이콘의 크기를 크게 설정 */
  color: white; /* 아이콘 색상 설정 */
}
</style>
