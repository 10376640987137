<template>
  <div class="navbar-container main-menu-content">
<!--    <horizontal-nav-menu-items :items="navMenuItems" />-->
    <horizontal-nav-menu-items v-if="customNavMenuItems.length > 0" :items="customNavMenuItems" />
    <horizontal-nav-menu-items v-else-if="loading === false" :items="navMenuItems" />
<!--    {{ customNavMenuItems }}-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_GAME_SET_MENU } from '@/store/settings/action'
import { FETCH_SET_BASIC } from '@/store/notice/action'

const settingsStore = createNamespacedHelpers('settingsStore')
const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  data() {
    return {
      customNavMenuItems: [],
      loading: true,
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
    }),
    ...noticeStore.mapGetters([
      'fetchSetBasic',
    ]),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
    }),
    ...noticeStore.mapActions({
      $fetchSetBasic: FETCH_SET_BASIC,
    }),
    async fetchData() {
      const mySite = this.$site
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      await this.$fetchSetBasic({
        site: this.$site,
      })
      // console.log('navMenuItems::::', navMenuItems)
      // console.log('$fetchSetBasic:::', this.fetchSetBasic[0])
      // console.log('$fetchGameSetMenu:::', this.fetchGameSetMenu)

      this.customNavMenuItems = navMenuItems
        .map(item => {
          const gameSetMenu = this.fetchGameSetMenu.find(menu => menu.name === item.title)
          if (gameSetMenu) {
            item.title = gameSetMenu.nameCustom
            item.type = gameSetMenu.type
            if (gameSetMenu.type === 'casino' && this.fetchSetBasic[0].casino !== 'Y') {
              return null // 조건에 맞지 않으면 제외
            }
            if (gameSetMenu.type === 'sports' && this.fetchSetBasic[0].sports !== 'Y') {
              return null // 조건에 맞지 않으면 제외
            }
            if (gameSetMenu.type === 'slot' && this.fetchSetBasic[0].slot !== 'Y') {
              return null // 조건에 맞지 않으면 제외
            }
            if (gameSetMenu.type === 'minigame' && this.fetchSetBasic[0].minigame !== 'Y') {
              return null // 조건에 맞지 않으면 제외
            }
            if (gameSetMenu.menuUseYn === 'N') {
              return null
            }
          }
          return item
        })
        .filter(item => item !== null)
      // console.log('customNavMenuItems:::', this.customNavMenuItems)
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
